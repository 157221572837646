import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Produkty from './components/Produkty';
import ScrollToTopAfterNavigating from './components/utils/ScrollToTopAfterNavigating';
import PlytyPIRComponent from './components/products/PlytyPIRComponent';
import PlytyWarstwoweComponent from './components/products/PlytyWarstwoweComponent';
import ContactComponent from './components/ContactComponent';
function App() {
  return (
    <Router>
      <ScrollToTopAfterNavigating/>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/produkty" element={<Produkty />} />
          <Route path="/plyty-warstwowe" element={<PlytyWarstwoweComponent />} />
          <Route path="/plyty-pir" element={<PlytyPIRComponent />} />
          <Route path="/kontakt" element={<ContactComponent />} />
          <Route path="*" element={<Home/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
