import React, { forwardRef } from 'react';

const ScrollSection = forwardRef(({ id, children }, ref) => {
  return (
    <section id={id} ref={ref} style={{paddingTop: '120px'}}>
      {children}
    </section>
  );
});

export default ScrollSection;
