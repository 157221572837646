import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import ScrollToTop from "../utils/ScrollToTop";
import PlytyPIR from "./PlytyPIR";

const PlytyPIRComponent = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const goToContact = () => {
    navigate("/", { state: { scrollTo: "contact" } });
  };
  const sectionRefs = {
    plytyPir: useRef(null),
    plytyWarstwowe: useRef(null),
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (sectionRefs[hash]) {
      sectionRefs[hash].current.scrollIntoView({ behavior: 'smooth' });
      setMobileNavOpen(false);
    }
  }, [location]);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  return (
    <div>
      <header className={scrollY > 0 ? "scroll" : ""}>
        <div className="header-content">
          <div className="logo">
            <a href="/">
              <img src="/nal_panel.jpg" alt="Logo" />
            </a>
          </div>
          <div
            className={`mobile-nav-icon ${isMobileNavOpen ? "open" : ""}`}
            onClick={toggleMobileNav}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <nav className="desktop-view">
            <ul>
              <li>
              <Link to="/">O nas</Link>
              </li>
              <li>
                <Link to="#">Płyty PIR</Link>
              </li>
              <li>
                <Link to="/plyty-warstwowe">Płyty warstwowe</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={`mobile-view ${isMobileNavOpen ? "open" : ""}`}>
          <ul>
            <li>
            <Link to="/">O nas</Link>
            </li>
            <li>
                    <Link to="#" onAnimationEnd={closeMobileNav}>Płyty PIR</Link>
                </li>
                  <li>
                      <Link to="/plytyWarstwowe" onAnimationEnd={closeMobileNav}>Płyty Warstwowe</Link>
                </li>
            <li>
            <Link to ="/kontakt" onAnimationEnd={closeMobileNav}> Kontakt </Link>
            </li>
          </ul>
        </div>
      </header>
      <main>
      <section id="produkty">
          <h1>Płyty PIR</h1>
          <p>
            Nasze płyty PIR to nowoczesne rozwiązanie w dziedzinie
            izolacji termicznej, dostępne na rynku. Zaprojektowane z myślą o
            efektywnej ochronie przed utratą ciepła, te lekkie i wytrzymałe
            płyty oferują doskonałą izolację termiczną. To idealne rozwiązanie
            dla osób poszukujących skutecznych metod poprawy efektywności
            energetycznej w swoich budynkach.
          </p>

          <h2>Główne cechy:</h2>
          <ul>
            <li>
              Wykonane z materiału poliizocyjanuratu (PIR) gwarantującego wysoką
              wydajność termiczną.
            </li>
            <li>
              Doskonała izolacja termiczna przyczyniająca się do znacznych
              oszczędności energii.
            </li>
            <li>
              Lekkie, co ułatwia instalację, minimalizując jednocześnie
              obciążenie konstrukcji.
            </li>
            <li>
              Trwała konstrukcja zapewniająca długotrwałą ochronę przed utratą
              ciepła.
            </li>
          </ul>

          <p>
            Dzięki naszym płytom PIR zyskasz pewność, że Twój dom lub
            obiekt przemysłowy są odpowiednio izolowane termicznie, co przekłada
            się na komfort i oszczędności energii.
          </p>
          <div>
          
              <PlytyPIR/>

          </div>
        </section>
        
         
      </main>
      <footer id="footer">
        <div className="footer-content">
          <div className="logo">
            <img src="/nal_panel_removedbg.png" alt="Footer Logo" />
          </div>
          <p>
            &copy; {new Date().getFullYear()} Nal-Panel. Wszelkie prawa
            zastrzeżone.
          </p>
        </div>
      </footer>
      <ScrollToTop/>
    </div>
  );
};

export default PlytyPIRComponent;
